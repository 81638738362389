import React from 'react';
import { bool } from 'prop-types';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemCustomTotalPriceMaybe = props => {
  const { intl, marketplaceCurrency, transaction, isProvider } = props;
  const { metadata } = transaction?.attributes || {};
  const { manualRefund } = metadata || {};

  const totalLabel = isProvider ? (
    <FormattedMessage id="OrderBreakdown.providerAmountReceived" />
  ) : (
    <FormattedMessage id="OrderBreakdown.customerAmountRefunded" />
  );

  const totalPrice = isProvider
    ? new Money(manualRefund?.providerReceivedAmount, marketplaceCurrency)
    : new Money(manualRefund?.customerRefundAmount, marketplaceCurrency);

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return formattedTotalPrice ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  ) : null;
};

LineItemCustomTotalPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomTotalPriceMaybe;
