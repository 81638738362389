import imgSilhouetteMobile from '../../assets/portrait/revels-silhouette.png';
import imgSilhouetteDesktop from '../../assets/landscape/revels-silhouette.png';
import imgMeasurementsMobile from '../../assets/portrait/revels-measurements.png';
import imgMeasurementsDesktop from '../../assets/landscape/revels-measurements.png';
import imgNecklineMobile from '../../assets/portrait/revels-ausschnitt.png';
import imgNecklineDesktop from '../../assets/landscape/revels-ausschnitt.png';
import imgBackMobile from '../../assets/portrait/revels-rucken.png';
import imgBackDesktop from '../../assets/landscape/revels-rucken.png';
import imgArmsMobile from '../../assets/portrait/revels-armel.png';
import imgArmsDesktop from '../../assets/landscape/revels-armel.png';

export const getHelpButtonContent = fieldId => {
  const listingFieldId = fieldId.includes('pub_') ? fieldId.split('pub_')[1] : fieldId;

  switch (listingFieldId) {
    case 'silhouette':
      return {
        buttonTextId: 'HelpButton.buttonText',
        buttonTextVariantId: 'HelpButton.buttonTextVariant',
        imgSrcMobile: imgSilhouetteMobile,
        imgSrcDesktop: imgSilhouetteDesktop,
      };

    case 'neckline':
      return {
        buttonTextId: 'HelpButton.buttonText',
        buttonTextVariantId: 'HelpButton.buttonTextVariant',
        imgSrcMobile: imgNecklineMobile,
        imgSrcDesktop: imgNecklineDesktop,
      };

    case 'back':
      return {
        buttonTextId: 'HelpButton.buttonText',
        buttonTextVariantId: 'HelpButton.buttonTextVariant',
        imgSrcMobile: imgBackMobile,
        imgSrcDesktop: imgBackDesktop,
      };

    case 'arms':
      return {
        buttonTextId: 'HelpButton.buttonText',
        buttonTextVariantId: 'HelpButton.buttonTextVariant',
        imgSrcMobile: imgArmsMobile,
        imgSrcDesktop: imgArmsDesktop,
      };

    case 'height':
      return {
        buttonTextId: 'HelpButton.buttonText',
        buttonTextVariantId: 'HelpButton.buttonTextVariant',
        imgSrcMobile: imgMeasurementsMobile,
        imgSrcDesktop: imgMeasurementsDesktop,
      };

    case 'bust':
      return {
        buttonTextId: 'HelpButton.buttonText',
        buttonTextVariantId: 'HelpButton.buttonTextVariant',
        imgSrcMobile: imgMeasurementsMobile,
        imgSrcDesktop: imgMeasurementsDesktop,
      };

    case 'waist':
      return {
        buttonTextId: 'HelpButton.buttonText',
        buttonTextVariantId: 'HelpButton.buttonTextVariant',
        imgSrcMobile: imgMeasurementsMobile,
        imgSrcDesktop: imgMeasurementsDesktop,
      };

    case 'hips':
      return {
        buttonTextId: 'HelpButton.buttonText',
        buttonTextVariantId: 'HelpButton.buttonTextVariant',
        imgSrcMobile: imgMeasurementsMobile,
        imgSrcDesktop: imgMeasurementsDesktop,
      };

    default:
      return null;
  }
};
