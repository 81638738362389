import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { Modal } from '../../components';
import { getHelpButtonContent } from './HelpButtonConfig';

import css from './HelpButton.module.css';

const HelpButton = props => {
  const {
    rootClassName,
    className,
    buttonClassName,
    onManageDisableScrolling,
    isScrollingDisabled,
    fieldId,
    withTextVariant,
    intl,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const content = getHelpButtonContent(fieldId);
  if (!content) return null;

  const { buttonTextId, buttonTextVariantId, imgSrcDesktop, imgSrcMobile } = content;

  const buttonText = intl.formatMessage({
    id: withTextVariant ? buttonTextVariantId : buttonTextId,
  });

  const classes = classNames(rootClassName || css.root, className);
  const buttonClasses = classNames(css.button, buttonClassName);

  return (
    <div className={classes}>
      <button className={buttonClasses} type="button" onClick={() => setIsOpen(true)}>
        {buttonText}
      </button>
      <Modal
        contentClassName={css.modalContent}
        id={`modal.${fieldId}`}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        isScrollingDisabled={isScrollingDisabled}
        usePortal
      >
        {imgSrcDesktop ? <img className={css.imgDesktop} src={imgSrcDesktop} /> : null}
        {imgSrcMobile ? <img className={css.imgMobile} src={imgSrcMobile} /> : null}
      </Modal>
    </div>
  );
};

HelpButton.defaultProps = {
  rootClassName: null,
  className: null,
  buttonClassName: null,
  withTextVariant: false,
};

HelpButton.propTypes = {
  rootClassName: string,
  className: string,
  buttonClassName: string,
  fieldId: string,
  withTextVariant: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(HelpButton);
