import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPackage.module.css';

const IconPackage = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="45"
      viewBox="0 -960 960 960"
      width="45"
      strokeWidth="2.75"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m380-548 100-50 100 50v-232H380v232ZM280-280v-80h200v80H280ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-660v600-600Zm0 600h600v-600H640v329l-160-80-160 80v-329H180v600Z" />
    </svg>
  );
};

IconPackage.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconPackage.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPackage;
