import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { IconHelp, IconInfo } from '../../components';

import css from './InfoBox.module.css';

const InfoBox = props => {
  const {
    rootClassName,
    className,
    imgClassName,
    imgSrc,
    title,
    text,
    withBorder,
    withBackground,
    isHelp,
    noIcon,
  } = props;

  const classes = classNames(rootClassName || css.root, className, {
    [css.withBorder]: withBorder,
    [css.withBackground]: withBackground,
  });
  const imgClasses = classNames(css.img, imgClassName);

  return (
    <div className={classes}>
      {noIcon ? null : isHelp ? (
        <IconHelp className={css.icon} />
      ) : (
        <IconInfo className={css.icon} />
      )}

      <div className={css.textWrapper}>
        {imgSrc ? <img className={imgClasses} src={imgSrc} /> : null}
        {title ? <span className={css.title}>{title}</span> : null}
        <span className={css.text}>{text}</span>
      </div>
    </div>
  );
};

InfoBox.defaultProps = {
  rootClassName: null,
  className: null,
  imgClassName: null,
  imgSrc: null,
  title: null,
  text: null,
  withBorder: false,
  withBackground: false,
  isHelp: false,
  noIcon: false,
};

InfoBox.propTypes = {
  rootClassName: string,
  className: string,
  imgClassName: string,
  imgSrc: string,
  title: string,
  text: string,
  withBorder: bool,
  withBackground: bool,
  isHelp: bool,
  noIcon: bool,
};

InfoBox.displayName = 'InfoBox';

export default InfoBox;
